<template>
    <div>
        <div class="top-header blackBG border-bottom">
            <div class="marquee border-top-0 border-bottom">
                <banner-slider></banner-slider>
            </div>
        </div>
        <section class="bg-white blockElement innerPage-banner">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="breadCrum d-flex align-items-center justify-content-between flex-wrap">
                            <div class="bdPage">
                                <h2>Chart Patterns</h2>
                                <nav aria-label="breadcrumb mt-2">
                                    <ol class="breadcrumb mb-0">
                                        <li class="breadcrumb-item"><router-link class="green" to="/">Home</router-link></li>
                                        <li class="breadcrumb-item active" aria-current="page">Chart Patterns</li>
                                    </ol>
                                </nav>
                            </div>
                            <div class="rightSearch">
                                <p>Powered by Autochartist</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement bg-white">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <carousel  :settings="settings" :breakpoints="breakpoints"><!--  :wrap-around="true" -->
                            <slide :key="1">
                                <div class="card border-0 box-shadow">
                                    <div class="card-body p-0">
                                        <div class="d-flex align-items-center justify-content-between border-bottom px-3 pb-2 pt-3">
                                            <div class="leftMt d-flex align-items-center">
                                                <img class="me-3" width="25" src="assets/images/market-trend-flag1.png" alt="" />
                                                <span class="medium flex-grow-1 text-start">
                                                    Euro / US Dollar
                                                    <span class="d-flex align-items-center f-12 medium">
                                                        1.0079 <span class="green mx-2 f-10">+0.0023</span> 
                                                        <span class="green d-flex align-items-center f-10"> 
                                                        <vue-feather class="me-1 sm-14" type="trending-up"></vue-feather> 0.25% </span>
                                                    </span>
                                                </span>
                                            </div>
                                            <div class="rightMt mt-n2">
                                                <a href="#"><img src="assets/images/fire.svg" alt="Icon" /></a>
                                            </div>
                                        </div>
                                        <div class="imgBlock px-3 pt-2"><img src="assets/images/emerging1.jpg" alt="Images" /></div>
                                        <div class="footerCard text-start px-3 py-2">
                                            <p class="d-flex align-items-center mb-0 medium">
                                            <img width="12" class="me-1" src="assets/images/arrowUp.svg" alt="Images" />Direction</p>
                                            <p class="mb-0 sm">Pattern: Falling Wedge</p>
                                        </div>
                                    </div>
                                </div>
                            </slide>
                            <slide :key="2">
                                <div class="card border-0 box-shadow">
                                    <div class="card-body p-0">
                                        <div class="d-flex align-items-center justify-content-between border-bottom px-3 pb-2 pt-3">
                                            <div class="leftMt d-flex align-items-center">
                                                <img class="me-3" width="25" src="assets/images/market-trend-flag1.png" alt="" />
                                                <span class="medium flex-grow-1 text-start">
                                                    Euro / US Dollar
                                                    <span class="d-flex align-items-center f-12 medium">
                                                        1.0079 <span class="green mx-2 f-10">+0.0023</span> 
                                                        <span class="green d-flex align-items-center f-10"> 
                                                        <vue-feather class="me-1 sm-14" type="trending-up"></vue-feather> 0.25% </span>
                                                    </span>
                                                </span>
                                            </div>
                                            <div class="rightMt mt-n2">
                                                <a href="#"><img src="assets/images/fire.svg" alt="Icon" /></a>
                                            </div>
                                        </div>
                                        <div class="imgBlock px-3 pt-2"><img src="assets/images/emerging1.jpg" alt="Images" /></div>
                                        <div class="footerCard text-start px-3 py-2">
                                            <p class="d-flex align-items-center mb-0 medium">
                                            <img width="12" class="me-1" src="assets/images/arrowUp.svg" alt="Images" />Direction</p>
                                            <p class="mb-0 sm">Pattern: Falling Wedge</p>
                                        </div>
                                    </div>
                                </div>
                            </slide>
                            <slide :key="3">
                                <div class="card border-0 box-shadow">
                                    <div class="card-body p-0">
                                        <div class="d-flex align-items-center justify-content-between border-bottom px-3 pb-2 pt-3">
                                            <div class="leftMt d-flex align-items-center">
                                                <img class="me-3" width="25" src="assets/images/market-trend-flag1.png" alt="" />
                                                <span class="medium flex-grow-1 text-start">
                                                    Euro / US Dollar
                                                    <span class="d-flex align-items-center f-12 medium">
                                                        1.0079 <span class="green mx-2 f-10">+0.0023</span> 
                                                        <span class="green d-flex align-items-center f-10"> 
                                                        <vue-feather class="me-1 sm-14" type="trending-up"></vue-feather> 0.25% </span>
                                                    </span>
                                                </span>
                                            </div>
                                            <div class="rightMt mt-n2">
                                                <a href="#"><img src="assets/images/fire.svg" alt="Icon" /></a>
                                            </div>
                                        </div>
                                        <div class="imgBlock px-3 pt-2"><img src="assets/images/emerging1.jpg" alt="Images" /></div>
                                        <div class="footerCard text-start px-3 py-2">
                                            <p class="d-flex align-items-center mb-0 medium">
                                            <img width="12" class="me-1" src="assets/images/arrowUp.svg" alt="Images" />Direction</p>
                                            <p class="mb-0 sm">Pattern: Falling Wedge</p>
                                        </div>
                                    </div>
                                </div>
                            </slide>
                            <slide :key="4">
                                <div class="card border-0 box-shadow">
                                    <div class="card-body p-0">
                                        <div class="d-flex align-items-center justify-content-between border-bottom px-3 pb-2 pt-3">
                                            <div class="leftMt d-flex align-items-center">
                                                <img class="me-3" width="25" src="assets/images/market-trend-flag1.png" alt="" />
                                                <span class="medium flex-grow-1 text-start">
                                                    Euro / US Dollar
                                                    <span class="d-flex align-items-center f-12 medium">
                                                        1.0079 <span class="green mx-2 f-10">+0.0023</span> 
                                                        <span class="green d-flex align-items-center f-10"> 
                                                        <vue-feather class="me-1 sm-14" type="trending-up"></vue-feather> 0.25% </span>
                                                    </span>
                                                </span>
                                            </div>
                                            <div class="rightMt mt-n2">
                                                <a href="#"><img src="assets/images/fire.svg" alt="Icon" /></a>
                                            </div>
                                        </div>
                                        <div class="imgBlock px-3 pt-2"><img src="assets/images/emerging1.jpg" alt="Images" /></div>
                                        <div class="footerCard text-start px-3 py-2">
                                            <p class="d-flex align-items-center mb-0 medium">
                                            <img width="12" class="me-1" src="assets/images/arrowUp.svg" alt="Images" />Direction</p>
                                            <p class="mb-0 sm">Pattern: Falling Wedge</p>
                                        </div>
                                    </div>
                                </div>
                            </slide>
                            <slide :key="5">
                                <div class="card border-0 box-shadow">
                                    <div class="card-body p-0">
                                        <div class="d-flex align-items-center justify-content-between border-bottom px-3 pb-2 pt-3">
                                            <div class="leftMt d-flex align-items-center">
                                                <img class="me-3" width="25" src="assets/images/market-trend-flag1.png" alt="" />
                                                <span class="medium flex-grow-1 text-start">
                                                    Euro / US Dollar
                                                    <span class="d-flex align-items-center f-12 medium">
                                                        1.0079 <span class="green mx-2 f-10">+0.0023</span> 
                                                        <span class="green d-flex align-items-center f-10"> 
                                                        <vue-feather class="me-1 sm-14" type="trending-up"></vue-feather> 0.25% </span>
                                                    </span>
                                                </span>
                                            </div>
                                            <div class="rightMt mt-n2">
                                                <a href="#"><img src="assets/images/fire.svg" alt="Icon" /></a>
                                            </div>
                                        </div>
                                        <div class="imgBlock px-3 pt-2"><img src="assets/images/emerging1.jpg" alt="Images" /></div>
                                        <div class="footerCard text-start px-3 py-2">
                                            <p class="d-flex align-items-center mb-0 medium">
                                            <img width="12" class="me-1" src="assets/images/arrowUp.svg" alt="Images" />Direction</p>
                                            <p class="mb-0 sm">Pattern: Falling Wedge</p>
                                        </div>
                                    </div>
                                </div>
                            </slide>
                            <slide :key="6">
                                <div class="card border-0 box-shadow">
                                    <div class="card-body p-0">
                                        <div class="d-flex align-items-center justify-content-between border-bottom px-3 pb-2 pt-3">
                                            <div class="leftMt d-flex align-items-center">
                                                <img class="me-3" width="25" src="assets/images/market-trend-flag1.png" alt="" />
                                                <span class="medium flex-grow-1 text-start">
                                                    Euro / US Dollar
                                                    <span class="d-flex align-items-center f-12 medium">
                                                        1.0079 <span class="green mx-2 f-10">+0.0023</span> 
                                                        <span class="green d-flex align-items-center f-10"> 
                                                        <vue-feather class="me-1 sm-14" type="trending-up"></vue-feather> 0.25% </span>
                                                    </span>
                                                </span>
                                            </div>
                                            <div class="rightMt mt-n2">
                                                <a href="#"><img src="assets/images/fire.svg" alt="Icon" /></a>
                                            </div>
                                        </div>
                                        <div class="imgBlock px-3 pt-2"><img src="assets/images/emerging1.jpg" alt="Images" /></div>
                                        <div class="footerCard text-start px-3 py-2">
                                            <p class="d-flex align-items-center mb-0 medium">
                                            <img width="12" class="me-1" src="assets/images/arrowUp.svg" alt="Images" />Direction</p>
                                            <p class="mb-0 sm">Pattern: Falling Wedge</p>
                                        </div>
                                    </div>
                                </div>
                            </slide>
                            
                            <template #addons>
                                <Navigation />
                                <!-- <pagination /> -->
                            </template>
                        </carousel>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement bg-white space">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-xl-9 mb-4 mb-xl-0">
                        <div class="row mx-0 shadowGroup">
                            <div class="col-12 col-xl-5">
                                <div class="d-flex justify-content-between align-items-center mb-2">
                                    <div class="grphBtn ms-0">
                                        <a href="#"><img src="assets/images/grid-gray.svg" alt="Grid" title="Grid View"> </a>
                                        <a href="#"><vue-feather type="refresh-ccw"></vue-feather> </a>
                                        <a href="#"><img src="assets/images/fire.svg" alt="Grid" title="Grid View"> </a>
                                    </div>
                                    <div class="grphBtn ms-0">
                                        <a href="javascript:void(0)" class="me-0">
                                            <vue-feather type="settings"></vue-feather> 
                                        </a> 
                                    </div>
                                </div>
                                <div class="chartPattern">
                                    <ul class="tableStructure">
                                        <li class="d-flex align-items-center justify-content-between">
                                            <strong class="d-flex align-items-center"> <img src="assets/images/chart3.png" class="me-2" alt="Icon" /> EUR/USD <span class="f-12">1.27%</span></strong>
                                            <span><img src="assets/images/bar-graph.png" class="" alt="Icon" /></span>
                                            <span>1,20379</span>
                                        </li>
                                        <li class="d-flex align-items-center justify-content-between">
                                            <strong class="d-flex align-items-center"> <img src="assets/images/chart2.png" class="me-2" alt="Icon" /> XAU/USD <span class="green f-12">1.27%</span></strong> 
                                            <span><img src="assets/images/bar-graph.png" class="" alt="Icon" /></span>
                                            <span class="red">1,20379</span>
                                        </li>
                                        <li class="d-flex align-items-center justify-content-between active">
                                            <strong class="d-flex align-items-center"> <img src="assets/images/chart1.png" class="me-2" alt="Icon" /> GBP/CHF <span class="red f-12">-1.27%</span></strong> 
                                            <span><img src="assets/images/bar-graph.png" class="" alt="Icon" /></span>
                                            <span class="green">1,20379</span>
                                        </li>
                                        <li class="d-flex align-items-center justify-content-between">
                                            <strong class="d-flex align-items-center"> <img src="assets/images/chart2.png" class="me-2" alt="Icon" /> XAU/USD <span class="green f-12">1.27%</span></strong> 
                                            <span><img src="assets/images/bar-graph.png" class="" alt="Icon" /></span>
                                            <span class="red">1,20379</span>
                                        </li>
                                        <li class="d-flex align-items-center justify-content-between">
                                            <strong class="d-flex align-items-center"> <img src="assets/images/chart3.png" class="me-2" alt="Icon" /> GBP/CHF <span class="green f-12">1.27%</span></strong> 
                                            <span><img src="assets/images/bar-graph.png" class="" alt="Icon" /></span>
                                            <span>1,20379</span>
                                        </li>
                                        <li class="d-flex align-items-center justify-content-between">
                                            <strong class="d-flex align-items-center"> <img src="assets/images/chart1.png" class="me-2" alt="Icon" /> EUR/USD <span class="red f-12">1.27%</span></strong> 
                                            <span><img src="assets/images/bar-graph.png" class="" alt="Icon" /></span>
                                            <span class="green">1,20379</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-12 col-xl-7">
                                <div class="pattern d-flex align-items-center justify-content-between">
                                    <p class="mb-0">Pattern: Falling Wedge</p>
                                    <p class="mb-0">Time Interval: 4 Hrs</p>
                                    <p class="mb-0">Direction: UP</p>
                                </div>
                                <img src="assets/images/chart/candle.svg" alt="Images" class="my-2 w-100" />
                                <p>Channel Up identified at 7/29 11:45. This pattern is still in the process of forming. Possible bearish price movement towards the support 129.8970 within the next 8 hours.</p>
                                <!-- <div class="sharePattern text-end">
                                    <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#staticBackdrop"><vue-feather size="17" type="share-2"></vue-feather></a>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-xl-3">
                        <div class="gridColumn text-center loginForm">
                            <h3 class="mb-4">Sign Up</h3>
                            <p class="f-15 medium mb-4">Create customised search, Volatility analysis, Performance statistics, Alerts etc.</p>
                            <div class="form-group"><input type="email" class="form-control" placeholder="Email" name="" /></div>
                            <div class="form-group"><input type="password" class="form-control" placeholder="Password" name="" /></div>
                            <div class="submitButton mt-3">
                                <a href="javascript:void(0)" class="grd_btn whiteBG">Create an Account</a>
                                <p class="mt-4">Already have an account? <a class="green" href="javascript:void(0)"> Login</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement bg-white space-footer">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-9">
                        <div class="d-flex justify-content-between align-items-start border-bottom flex-wrap mb-2 mb-md-5">
                            <ul class="toplinks no-wrap pb-2 d-flex align-items-center mb-0">
                                <li class="ps-0"><a href="javascript:void(0)" class="active">Completed Patterns</a></li>
                                <li><a href="javascript:void(0)">Emerging Chart Patterns</a></li>
                                <li><a href="javascript:void(0)">Approaching Chart Patterns</a></li>
                            </ul>
                            <ul class="toplinks d-flex align-items-center mb-0 pb-2 dualTabs">
                                <li class="ps-0"><a href="#" class="active">All</a></li>
                                <li><a href="#">Forex</a></li>
                                <li><a href="#">Crypto</a></li>
                                <li><a href="#">Currencies</a></li>
                                <li><a href="#">Indices</a></li>
                                <li><a href="#">Stocks</a></li>
                            </ul>
                        </div>
                        <div class="row mob-scroll-content py-3 py-xl-0 mb-5 mb-xl-0">
                            <div class="col-12 col-xl-6">
                                <div class="card border-0 mb-4 midNew">
                                    <div class="card-body">
                                        <h4 class="card-title"><a href="#">Deep Value and Bullish Sentiment in La-Z-Boy</a> <span class="modifyDate">29 July</span></h4>
                                        <img src="assets/images/graph.jpg" class="img-responsive w-100" alt="Chart" title="News Chart" />
                                        <div class="d-flex align-items-center justify-content-between ideas-currency-row my-3">
                                            <div class="forexnood d-flex align-items-center"><img src="assets/images/forexnoob.jpg" alt="" /> <span class="mx-2">forexnoob</span> <img src="assets/images/badge.jpg" alt="" /></div>
                                            <div class="currency-wrap">
                                                <span class="currency"><a href="#">EURUSD</a></span> <span class="monthly"><a href="#">monthly</a></span>
                                                <span class="short">
                                                    <a href="#" class="redBG"><i data-feather="arrow-down-right"></i> Short</a>
                                                </span>
                                            </div>
                                        </div>
                                        <p>The Australian Dollar also weakened against the Japanese Yen, then trimming most losses as Friday wrapped up. On the daily chart, AUD/JPY left behind and subsequently confirmed</p>
                                        <div class="smallbtn d-flex align-items-center"><span class="cardEelement">Technical Analysis</span> <span class="cardEelement">Technical Analysis</span></div>
                                        <div class="btm-row d-flex align-items-center justify-content-between border-top mt-4 pt-3">
                                            <div>
                                                <span><i class="fa fa-heart-o" aria-hidden="true"></i> 14</span>
                                            </div>
                                            <div class="righticons">
                                                <span class="pointer" data-bs-toggle="modal" data-bs-target="#staticBackdrop1"><i class="fa fa-comments-o" aria-hidden="true"></i> 37</span> <span class="pointer" data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i class="fa fa-share-alt" aria-hidden="true"></i> 20</span> <span><i class="fa fa-bookmark-o" aria-hidden="true"></i> 10</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-xl-6">
                                <div class="card border-0 mb-4 midNew">
                                    <div class="card-body">
                                        <h4 class="card-title"><a href="#">Deep Value and Bullish Sentiment in La-Z-Boy</a> <span class="modifyDate">29 July</span></h4>
                                        <img src="assets/images/graph.jpg" class="img-responsive w-100" alt="Chart" title="News Chart" />
                                        <div class="d-flex align-items-center justify-content-between ideas-currency-row my-3">
                                            <div class="forexnood d-flex align-items-center"><img src="assets/images/forexnoob.jpg" alt="" /> <span class="mx-2">forexnoob</span> <img src="assets/images/badge.jpg" alt="" /></div>
                                            <div class="currency-wrap">
                                                <span class="currency"><a href="#">EURUSD</a></span> <span class="monthly"><a href="#">monthly</a></span>
                                                <span class="short">
                                                    <a href="#" class="redBG"><i data-feather="arrow-down-right"></i> Short</a>
                                                </span>
                                            </div>
                                        </div>
                                        <p>The Australian Dollar also weakened against the Japanese Yen, then trimming most losses as Friday wrapped up. On the daily chart, AUD/JPY left behind and subsequently confirmed</p>
                                        <div class="smallbtn d-flex align-items-center"><span class="cardEelement">Technical Analysis</span> <span class="cardEelement">Technical Analysis</span></div>
                                        <div class="btm-row d-flex align-items-center justify-content-between border-top mt-4 pt-3">
                                            <div>
                                                <span><i class="fa fa-heart-o" aria-hidden="true"></i> 14</span>
                                            </div>
                                            <div class="righticons">
                                                <span><i class="fa fa-comments-o" aria-hidden="true"></i> 37</span> <span><i class="fa fa-share-alt" aria-hidden="true"></i> 20</span> <span><i class="fa fa-bookmark-o" aria-hidden="true"></i> 10</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-xl-6">
                                <div class="card border-0 mb-4 midNew">
                                    <div class="card-body">
                                        <h4 class="card-title"><a href="#">Deep Value and Bullish Sentiment in La-Z-Boy</a> <span class="modifyDate">29 July</span></h4>
                                        <img src="assets/images/graph.jpg" class="img-responsive w-100" alt="Chart" title="News Chart" />
                                        <div class="d-flex align-items-center justify-content-between ideas-currency-row my-3">
                                            <div class="forexnood d-flex align-items-center"><img src="assets/images/forexnoob.jpg" alt="" /> <span class="mx-2">forexnoob</span> <img src="assets/images/badge.jpg" alt="" /></div>
                                            <div class="currency-wrap">
                                                <span class="currency"><a href="#">EURUSD</a></span> <span class="monthly"><a href="#">monthly</a></span>
                                                <span class="short">
                                                    <a href="#" class="redBG"><i data-feather="arrow-down-right"></i> Short</a>
                                                </span>
                                            </div>
                                        </div>
                                        <p>The Australian Dollar also weakened against the Japanese Yen, then trimming most losses as Friday wrapped up. On the daily chart, AUD/JPY left behind and subsequently confirmed</p>
                                        <div class="smallbtn d-flex align-items-center"><span class="cardEelement">Technical Analysis</span> <span class="cardEelement">Technical Analysis</span></div>
                                        <div class="btm-row d-flex align-items-center justify-content-between border-top mt-4 pt-3">
                                            <div>
                                                <span><i class="fa fa-heart-o" aria-hidden="true"></i> 14</span>
                                            </div>
                                            <div class="righticons">
                                                <span><i class="fa fa-comments-o" aria-hidden="true"></i> 37</span> <span><i class="fa fa-share-alt" aria-hidden="true"></i> 20</span> <span><i class="fa fa-bookmark-o" aria-hidden="true"></i> 10</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-xl-6">
                                <div class="card border-0 mb-4 midNew">
                                    <div class="card-body">
                                        <h4 class="card-title"><a href="#">Deep Value and Bullish Sentiment in La-Z-Boy</a> <span class="modifyDate">29 July</span></h4>
                                        <img src="assets/images/graph.jpg" class="img-responsive w-100" alt="Chart" title="News Chart" />
                                        <div class="d-flex align-items-center justify-content-between ideas-currency-row my-3">
                                            <div class="forexnood d-flex align-items-center"><img src="assets/images/forexnoob.jpg" alt="" /> <span class="mx-2">forexnoob</span> <img src="assets/images/badge.jpg" alt="" /></div>
                                            <div class="currency-wrap">
                                                <span class="currency"><a href="#">EURUSD</a></span> <span class="monthly"><a href="#">monthly</a></span>
                                                <span class="short">
                                                    <a href="#" class="redBG"><i data-feather="arrow-down-right"></i> Short</a>
                                                </span>
                                            </div>
                                        </div>
                                        <p>The Australian Dollar also weakened against the Japanese Yen, then trimming most losses as Friday wrapped up. On the daily chart, AUD/JPY left behind and subsequently confirmed</p>
                                        <div class="smallbtn d-flex align-items-center"><span class="cardEelement">Technical Analysis</span> <span class="cardEelement">Technical Analysis</span></div>
                                        <div class="btm-row d-flex align-items-center justify-content-between border-top mt-4 pt-3">
                                            <div>
                                                <span><i class="fa fa-heart-o" aria-hidden="true"></i> 14</span>
                                            </div>
                                            <div class="righticons">
                                                <span><i class="fa fa-comments-o" aria-hidden="true"></i> 37</span> <span><i class="fa fa-share-alt" aria-hidden="true"></i> 20</span> <span><i class="fa fa-bookmark-o" aria-hidden="true"></i> 10</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-3">
                        <div class="selectMainPattern">
                            <div class="d-flex align-items-center justify-content-between border-bottom mb-4 mb-md-5">
                                <ul class="toplinks d-flex align-items-center mb-0 pb-2">
                                    <li class="ps-0"><a href="javascript:void(0)" class="active">All</a></li>
                                    <li><a href="javascript:void(0)">Bull</a></li>
                                    <li><a href="javascript:void(0)">Bear</a></li>
                                </ul>
                                <div class="d-none d-xl-block form-group inputIcon position-relative mb-0">
                                    <input type="text" placeholder="EUR USD" name="" class="form-control h-30" /> <a class="searchButton" href="#"><vue-feather type="search"></vue-feather> </a>
                                </div>
                            </div>
                            <div class="accordianTab">
                                <a @click.prevent="chartPattern = !chartPattern" href="javascript:void(0)" class="accorditanTitle d-flex align-items-center justify-content-between">
                                    <h6 class="mb-0">Chart Patterns</h6>
                                    <vue-feather size="20px" :type="chartPattern?'chevron-up':'chevron-down'"></vue-feather>
                                </a>
                                <ul class="contentList m-0 py-2" :class="chartPattern?'active':''">
                                    <li>
                                        <label class="customCheckbox mb-0"> All <input type="checkbox" /> <span class="checkmark"></span> </label>
                                    </li>
                                    <li>
                                        <label class="customCheckbox mb-0"> Ascending Triangle <input type="checkbox" /> <span class="checkmark"></span> </label>
                                    </li>
                                    <li>
                                        <label class="customCheckbox mb-0"> Descending Triangle <input type="checkbox" /> <span class="checkmark"></span> </label>
                                    </li>
                                    <li>
                                        <label class="customCheckbox mb-0"> Double Bottom <input type="checkbox" /> <span class="checkmark"></span> </label>
                                    </li>
                                    <li>
                                        <label class="customCheckbox mb-0">  Triple Top <input type="checkbox" /> <span class="checkmark"></span> </label>
                                    </li>
                                    <li>
                                        <label class="customCheckbox mb-0"> Head and Shoulders <input type="checkbox" /> <span class="checkmark"></span> </label>
                                    </li>
                                    <li>
                                        <label class="customCheckbox mb-0"> Inverse Head and Shoulders <input type="checkbox" /> <span class="checkmark"></span> </label>
                                    </li>
                                    <li>
                                        <label class="customCheckbox mb-0"> Rising Wedge <input type="checkbox" /> <span class="checkmark"></span> </label>
                                    </li>       
                                    <li class="readMore">
                                        <a href="javascript:void(0)" class="green medium"> + Show more</a>
                                    </li>                             
                                </ul>
                            </div>
                            <div class="accordianTab">
                                <a @click.prevent="chartPattern1 = !chartPattern1" href="javascript:void(0)" class="accorditanTitle d-flex align-items-center justify-content-between">
                                    <h6 class="mb-0">Fibonacci Pattern</h6>
                                    <vue-feather size="20px" :type="chartPattern1?'chevron-up':'chevron-down'"></vue-feather>
                                </a>
                                <ul class="contentList m-0 py-2" :class="chartPattern1?'active':''">
                                    <li>
                                        <label class="customCheckbox mb-0"> All <input type="checkbox" /> <span class="checkmark"></span> </label>
                                    </li>
                                    <li>
                                        <label class="customCheckbox mb-0">3 Point Extension<input type="checkbox" /> <span class="checkmark"></span> </label>
                                    </li>
                                    <li>
                                        <label class="customCheckbox mb-0">3 Point Retracement <input type="checkbox" /> <span class="checkmark"></span> </label>
                                    </li>
                                    <li>
                                        <label class="customCheckbox mb-0">ABCD<input type="checkbox" /> <span class="checkmark"></span> </label>
                                    </li>
                                    <li>
                                        <label class="customCheckbox mb-0"> 3 Drive<input type="checkbox" /> <span class="checkmark"></span> </label>
                                    </li>       
                                    <li class="readMore">
                                        <a href="javascript:void(0)" class="green medium"> + Show more</a>
                                    </li>                             
                                </ul>
                            </div>
                            <div class="accordianTab">
                                <a @click.prevent="chartPattern2 = !chartPattern2" href="javascript:void(0)" class="accorditanTitle d-flex align-items-center justify-content-between">
                                    <h6 class="mb-0">Key Levels</h6>
                                    <vue-feather size="20px" :type="chartPattern2?'chevron-up':'chevron-down'"></vue-feather>
                                </a>
                                <ul class="contentList m-0 py-2" :class="chartPattern2?'active':''">
                                    <li>
                                        <label class="customCheckbox mb-0"> All <input type="checkbox" /> <span class="checkmark"></span> </label>
                                    </li>
                                    <li>
                                        <label class="customCheckbox mb-0">3 Point Extension<input type="checkbox" /> <span class="checkmark"></span> </label>
                                    </li>
                                    <li>
                                        <label class="customCheckbox mb-0">3 Point Retracement <input type="checkbox" /> <span class="checkmark"></span> </label>
                                    </li>
                                    <li>
                                        <label class="customCheckbox mb-0">ABCD<input type="checkbox" /> <span class="checkmark"></span> </label>
                                    </li>
                                    <li>
                                        <label class="customCheckbox mb-0"> 3 Drive<input type="checkbox" /> <span class="checkmark"></span> </label>
                                    </li>       
                                    <li class="readMore">
                                        <a href="javascript:void(0)" class="green medium"> + Show more</a>
                                    </li>                             
                                </ul>
                            </div>
                            <div class="accordianTab">
                                <a @click.prevent="chartPattern3 = !chartPattern3" href="javascript:void(0)" class="accorditanTitle d-flex align-items-center justify-content-between">
                                    <h6 class="mb-0">Interval  </h6>
                                    <vue-feather size="20px" :type="chartPattern3?'chevron-up':'chevron-down'"></vue-feather>
                                </a>
                                <ul class="contentList m-0 py-2" :class="chartPattern3?'active':''">
                                    <li>
                                        <label class="customCheckbox mb-0"> All <input type="checkbox" /> <span class="checkmark"></span> </label>
                                    </li>
                                    <li>
                                        <label class="customCheckbox mb-0">Horizontal Resistance<input type="checkbox" /> <span class="checkmark"></span> </label>
                                    </li>
                                    <li>
                                        <label class="customCheckbox mb-0">Horizontal Support<input type="checkbox" /> <span class="checkmark"></span> </label>
                                    </li>      
                                    <li class="readMore">
                                        <a href="javascript:void(0)" class="green medium"> + Show more</a>
                                    </li>                             
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    <!-- Share Modal -->
    <div class="modal fade" id="staticBackdrop" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content shareModal">
                <div class="modal-header border-0 pb-0">
                    <h5 class="modal-title" id="staticBackdropLabel">Share</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="card border mb-4">
                        <div class="card-body">
                            <h4 class="card-title"><a href="javascript:void(0)">Deep Value and Bullish Sentiment in La-Z-Boy</a> <span class="modifyDate">29 July</span></h4>
                            <img src="assets/images/graph.jpg" class="img-responsive w-100" alt="Chart" title="Share Chart">
                            <div class="form-group mb-0">
                                <label class="mb-1">Description</label>
                                <textarea class="form-control" placeholder="Enter description here"></textarea>
                            </div>
                        </div>
                    </div>
                    <ul class="userShare-list d-flex align-items-center">
                        <li><a href="javascript:void(0)"><span><img src="assets/images/linkedin-share.svg" alt="Linkedin" title="Linkedin Share Link"></span> Linkedin</a></li>
                        <li><a href="javascript:void(0)"><span><img src="assets/images/twitter-share.svg" alt="Twitter" title="Twitter Share Link"></span> Twitter</a></li>
                        <li><a href="javascript:void(0)"><span><img src="assets/images/whatsapp-share.svg" alt="Whatsapp" title="Whatsapp Share Link"></span> Whatsapp</a></li>
                        <li><a href="javascript:void(0)"><span><img src="assets/images/facebook-share.svg" alt="facebook" title="Facebook Share Link"></span> Facebook</a></li>
                        <li><a href="javascript:void(0)"><span><img src="assets/images/blog-share.svg" alt="Blog" title="Blog Share Link"></span> Blog</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- End -->
    <!-- Comment  Open Modal -->
    <div class="modal fade ideaModal" id="staticBackdrop1"  aria-labelledby="staticBackdrop1Label" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="row modal-content g-0 h-100">
                <div class="col-12 col-lg-6 ideaChart">
                    <img class="d-block w-100 h-100" src="assets/images/chart/thumbnail.svg" alt="Chart" title="Deep Value and Bullish Sentiment in La-Z-Boy">
                </div>  
                <div class="col-12 col-lg-6 d-flex flex-column ideaChart border-start">
                    <div class="modal-body p-4 pt-0 border-0">
                        <div class="d-flex align-items-center justify-content-between modalHeader py-3">
                            <ul class="d-flex align-items-center mb-0">
                                <li><a href="javascript:void(0)" class="d-flex me-3"><vue-feather size="23px" type="share-2"></vue-feather></a></li>
                                <li><a href="javascript:void(0)" class="d-flex "><vue-feather size="23px" type="printer"></vue-feather></a></li>
                            </ul>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <p>Hi, i have a question regarding purchase of same stocks (different quantities) in both NSE and BSE. Example if i purchase 5 stocks of Apollotyres in NSE @100 and then purchasing 5 in BSE @90, will my portfolio display apollotyres twice one with BSE and another with NSE with respective averages?</p>
                        <ul class="commentsPanel">
                            <li class="d-flex position-relative addBordered mb-4">
                                <div class="flex-shrink-0 position-relative">
                                    <img src="assets/images/comment-1.png" alt="Profile Picture" title="Arlene McCoy">
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <h6 class="mb-1 medium">Arlene McCoy</h6>
                                    <p class="lighttext">2 days ago</p>
                                    <p>Hi, i have a question regarding purchase of same stocks (different quantities) in both NSE and BSE. Example if i purchase 5 stocks of Apollotyres in NSE @100 and then purchasing 5 in BSE @90, will my portfolio display apollotyres twice one with BSE and another with NSE with respective averages?</p>
                                    <div class="d-flex align-items-center replies">
                                        <p class="mb-0 d-inline-flex align-items-center medium me-4"><vue-feather class="me-1 greenBG" type="heart"></vue-feather> 4 Likes</p>
                                        <p class="mb-0 d-inline-flex align-items-center medium"><vue-feather class="me-1" size="19px" type="message-circle"></vue-feather> 2 Replies</p>
                                        <!-- <p class="mb-0 d-inline-flex align-items-center"><vue-feather size="19px" type="heart"></vue-feather> 4 Likes</p> -->
                                    </div>
                                </div>
                            </li>
                            <li class="d-flex position-relative addBordered mb-4">
                                <div class="flex-shrink-0 position-relative">
                                    <img src="assets/images/comment-1.png" alt="Profile Picture" title="Arlene McCoy">
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <h6 class="mb-1 medium">Arlene McCoy</h6>
                                    <p class="lighttext">2 days ago</p>
                                    <p>Hi, i have a question regarding purchase of same stocks (different quantities) in both NSE and BSE. Example if i purchase 5 stocks of Apollotyres in NSE @100 and then purchasing 5 in BSE @90, will my portfolio display apollotyres twice one with BSE and another with NSE with respective averages?</p>
                                    <div class="d-flex align-items-center replies">
                                        <p class="mb-0 d-inline-flex align-items-center medium me-4"><vue-feather class="me-1 greenBG" type="heart"></vue-feather> 4 Likes</p>
                                        <p class="mb-0 d-inline-flex align-items-center medium"><vue-feather class="me-1" size="19px" type="message-circle"></vue-feather> 2 Replies</p>
                                        <!-- <p class="mb-0 d-inline-flex align-items-center"><vue-feather size="19px" type="heart"></vue-feather> 4 Likes</p> -->
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div class="postBox form-group">
                            <div class="d-flex align-items-center mb-4">
                                <div class="flex-shrink-0 position-relative me-3">
                                    <span class="nameValue">A</span>
                                </div>
                                <textarea class="form-control h-70 textarea mb-0" placeholder="Post a comment"></textarea>
                            </div>
                            <div class="d-flex align-items-center justify-content-end">
                                <a href="javascript:void(0)" class="border-btn green me-2">Cancel</a>
                                <a href="javascript:void(0)" class="grd_btn small">Submit</a>
                            </div>
                        </div>

                    </div>
                    <div class="modalFooter p-0 border-0">
                        <div class="prevNextNews border-top trade d-flex align-items-center justify-content-between">
                            <a href="javascript:void(0)" class="border-end prev d-flex align-items-center"><i class="me-2 fa fa-caret-left" aria-hidden="true"></i>Previous</a>
                            <a href="javascript:void(0)" class="border-start prev d-flex align-items-center">Next <i class="ms-2 fa fa-caret-right" aria-hidden="true"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<!-- End -->
    </div>
</template>

<script>
import bannerslider from "../../components/shared/banner_slider";
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation,  } from 'vue3-carousel';
export default {
    data() {
        return {
            chartPattern: true,
            chartPattern1: true,
            chartPattern2: false,
            chartPattern3: false,
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            breakpoints: {
                // 700px and up
                767: {
                  itemsToShow: 2,
                  snapAlign: 'center',
                },
                1024: {
                  itemsToShow: 3,
                  snapAlign: 'start',
                },
                1199: {
                  itemsToShow: 5,
                  snapAlign: 'start',
                },
            },
        }
    },
    components: {
        bannerSlider:bannerslider,
        Carousel,
        Slide,
        Navigation,

    },     
}
</script>
